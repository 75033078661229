import React, { useEffect, useState } from 'react';

const TypingText = () => {
  const words = ['CS2', 'Rust', 'DOTA2', 'TF2'];
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const handleType = () => {
      const currentWord = words[wordIndex];
      const currentText = isDeleting
        ? currentWord.substring(0, charIndex - 1)
        : currentWord.substring(0, charIndex + 1);

      setText(currentText);

      if (!isDeleting && charIndex === currentWord.length) {
        setTimeout(() => setIsDeleting(true), 2400);
      } else if (isDeleting && charIndex === 0) {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }

      setCharIndex((prevCharIndex) => prevCharIndex + (isDeleting ? -1 : 1));
    };

    const typingTimeout = setTimeout(handleType, isDeleting ? 100 : 200);

    return () => clearTimeout(typingTimeout);
  }, [charIndex, isDeleting, wordIndex, words]);

  return (
        <div className={`relative mr-6 text-primary-50 blink`}>{text}</div>
  );
};

export default TypingText;
