'use client';
import Head from 'next/head';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FAQ from '@/components/FAQ';
import Footer from '@/components/Footer';
import HowToSell from '@/components/HowToSell';
import JoinDiscord from '@/components/JoinDiscord';
import RecentlySold from '@/components/RecentlySold';
import Slider from '@/components/Slider';
import Testimonials from '@/components/Testimonial';
import WeeklyGiveaway from '@/components/WeeklyGiveaway';

import { AppDispatch } from '@/store';
import { fetchUser } from '@/store/userSlice';

export default function HomePage() {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <main>
      <Head>
        <title>Welcome</title>
      </Head>

      <div className='flex flex-col gap-10'>
        <Slider />
        <RecentlySold />
        <WeeklyGiveaway />
        <HowToSell />
        <Testimonials />
        <FAQ />
        <JoinDiscord />
      </div>

      <Footer />
    </main>
  );
}
