import Link from 'next/link';

import Button from '@/components/ui/buttons/Button';
import NextImage from '@/components/ui/NextImage';

import Container from '@/layout/Container';
import TrustpilotReviewsLink from '@/components/TrustpilotReviewsLink';
import TypingText from '@/components/TypingText';

import Pixels from '~/svg/Pixels.svg';
import { smoothScrollToDiv } from '@/lib/helper';

import AmazonLogo from '~/images/paymentmethods/amazon.svg';
import BitcoinLogo from '~/images/paymentmethods/bitcoin.svg';
import EthLogo from '~/images/paymentmethods/eth.svg';
import PaypalLogo from '~/images/paymentmethods/paypal.svg';
import TetherLogo from '~/images/paymentmethods/tether.svg';

export default function Slider() {
  const methods = [
    { name: 'eth', logo: <EthLogo className="max-w-[90px]" />, },
    { name: 'btc', logo: <BitcoinLogo className="max-w-[90px]" />, },
    { name: 'amazon', logo: <AmazonLogo className="max-w-[90px]" />, },
    { name: 'paypal', logo: <PaypalLogo className="max-w-[90px]" />, },
    { name: 'tether', logo: <TetherLogo className="max-w-[90px]" />, },
  ];
  
  return (
    <div className="w-full overflow-hidden">
      <div
        style={{
          backgroundImage: "url('/images/hero-bg.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container>
          <div className="flex items-center justify-between gap-12 pb-28 pt-24 lg:pt-36">
            <div className="flex flex-col gap-24">
              <div className="flex flex-col gap-5">
                <div className="text-[54px] sm:text-6xl font-normal capitalize leading-[80px] sm:leading-[80px] text-white">
                  <span className="flex flex-col md:flex-row">
                    <span className="flex flex-wrap">
                      <span className="mr-4">SELL</span>
                      <TypingText />
                    </span>
                    <Pixels className="relative mt-1 inline-block w-60 h-20" />
                  </span>
                  <span className="block">FOR REAL MONEY</span>
                  <span className="text-primary-50 block">INSTANTLY</span>
                </div>
                <div className="font-secondary mb-4 text-xl font-normal text-white">
                  Sell your pixelated In-Game Items for real cash.
                </div>
                <TrustpilotReviewsLink />
              </div>

              <div className="flex flex-col sm:flex-row gap-6">
                <Link href="/sell" className="w-ful sm:w-auto">
                  <Button variant="primary" className="w-full sm:w-auto">Sell your skins</Button>
                </Link>
                <Button
                  onClick={() => smoothScrollToDiv('how-to-sell')}
                  variant="outline"
                >
                  How to sell
                </Button>
              </div>
            </div>

            <div className="relative hidden flex-col gap-6 xl:flex xl:gap-12 xl:pr-32">
              <NextImage
                useSkeleton
                className="h-[200px] w-[300px] xl:w-[480px]"
                alt="Ak47"
                fill={false}
                src="/images/hero-knife.png"
              />
              <NextImage
                useSkeleton
                className="h-[200px] w-[300px] xl:w-[480px]"
                alt="Ak47"
                fill={false}
                src="/images/hero-ak47.png"
              />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="mb-20 flex w-full items-center justify-between gap-8">
          {methods.map((method, key) => (
            <div
              key={key}
              className="inline-flex w-52 flex-col items-center justify-center gap-2.5 px-6 py-5"
            >
              {method.logo}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
